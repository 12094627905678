import { DatePipe }                from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, inject, ApplicationRef, Injector, Type, ComponentFactoryResolver, ComponentRef } from '@angular/core';
import { Synaps }                  from "@synaps-io/verify-sdk";
import SnsWebSdk                   from "@sumsub/websdk";
//import { showPolicyById }          from "@securely.id/websdk";
import { Observable }              from "rxjs";
import { ENVIRONMENT }             from "../../injection tokens";
import { Environment }             from "../../interfaces";
import { KybDelegationComponent, UserInfoComponent }       from "../../components";


@Injectable({
  providedIn: "root",
})
export class OnboardingService {
  constructor(private http: HttpClient) {
    if (typeof window !== "undefined") {
      this.urlParams = new URLSearchParams(window.location.search);
    }
  }

  private readonly environment: Environment = inject<Environment>(ENVIRONMENT);

  private baseUrl = this.environment.backendAddress;
  private requestIdCounter = 1;
  private providers: any = null;
  private authRequired = false;
  private modalRequired = false;
  private urlParams: any = null;

  private appRef = inject(ApplicationRef);
  private injector = inject(Injector);
  private componentFactoryResolver = inject(ComponentFactoryResolver);

  private userInfoModalResolve: ((value: UserInfo) => void) | null = null;
  private userInfoModalReject: ((reason?: any) => void) | null = null;

  private kybDelegationModalResolve: ((value: string) => void) | null = null;
  private kybDelegationModalReject: ((reason?: any) => void) | null = null;

  private getNextRequestId() {
    return this.requestIdCounter++;
  }

  private makeSecurelyCall(path: string, method: string, params: any, token: string | null): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (token != null) {
      headers = headers.append(
        'Authorization', 'Bearer ' + token,
      );
    }

    const requestBody = {
      jsonrpc: '2.0',
      method: method,
      params: params,
      id: this.getNextRequestId(),
    };

    return this.http.post(`${this.baseUrl}/${path}`, JSON.stringify(requestBody), { headers });
  }

  public shouldDisplayCustomModal(): boolean {
    return this.modalRequired;
  }

  public isAuthRequired(): boolean {
    return this.authRequired;
  }

  public getProviders() {
    return this.providers;
  }

  public fetchProviders() {
    const path = 'api/v0/onboarding';
    const method = 'getProviders';
    const params = {
      methodId: this.getMethodId()
    };

    return new Promise<void>((resolve, reject) => {
      if (params.methodId === null) {
        reject('MethodID is missing');
        return;
      }

      this.makeSecurelyCall(path, method, params, null).subscribe({
        next: (response) => {
          console.log('RPC Response:', response);
          if (response.error) {
            console.error('JSON-RPC Error:', response.error);
            reject(new Error('JSON-RPC Error'));
          } else {
            console.log(response.result);
            this.providers = response.result;
            /* Store whether authentication is required or not */
            for (const provider of this.providers) {
              if (provider.type.toLowerCase() === "auth" && provider.name.toLowerCase() === "webauthn") {
                  /* Authentication required (WebauthN only for now) */
                  this.authRequired = true;
                  break;
              }
            }
            resolve();
          }
        },
        error: (error) => {
          console.error('RPC Error:', error);
          reject(error);
        }
      });
    });
  }

  private injectComponent(component: Type<any>, containerId: string) {
    const container = document.getElementById(containerId);
    if (!container) {
      console.error(`Container with id ${containerId} not found`);
      return;
    }

    while (container.firstChild) {
      container.removeChild(container.firstChild);
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    const componentRef = componentFactory.create(this.injector);

    /* Attach component to app */
    this.appRef.attachView(componentRef.hostView);

    /* Insert component into DOM */
    container.appendChild((componentRef.hostView as any).rootNodes[0]);
    return componentRef;
  }

  private async askUserInfo(showUserType: boolean, showKycFields: boolean, showKybFields: boolean, userType: string): Promise<UserInfo> {
    return new Promise((resolve, reject) => {
      this.modalRequired = true;
      const ref = this.injectComponent(UserInfoComponent, 'custom-modal');
      (<ComponentRef<UserInfoComponent>>ref).instance.showUserType = showUserType;
      (<ComponentRef<UserInfoComponent>>ref).instance.showKycFields = showKycFields;
      (<ComponentRef<UserInfoComponent>>ref).instance.showKybFields = showKybFields;
      (<ComponentRef<UserInfoComponent>>ref).instance.userType = userType;
      this.userInfoModalResolve = resolve;
      this.userInfoModalReject = reject;
    });
  }

  public submitUserInfo(userInfo: UserInfo) {
    this.modalRequired = false;
    if (this.userInfoModalResolve != null) {
      this.userInfoModalResolve(userInfo);
    }
  }

  private async askKybDelegation(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.modalRequired = true;
      const ref = this.injectComponent(KybDelegationComponent, 'custom-modal');
      this.kybDelegationModalResolve = resolve;
      this.kybDelegationModalReject = reject;
    });
  }

  public submitKybDelegation(addresses: string) {
    this.modalRequired = false;
    if (this.kybDelegationModalResolve != null) {
      this.kybDelegationModalResolve(addresses);
    }
  }

  private pairing(token: string): Promise<string> {
    return this
      .doPairing("", token)
      .then<string>((challenge: string) => {
        return this.signEthereumMessage(challenge)
      })
      .then<string>((signature: string) => {
        return this.doPairing(signature, token)
      });
  }

  private async doPairing(signature: string, token: string): Promise<string> {
    const path = 'api/v0/onboarding';
    const method = 'pairing';
    const params = [signature];

    return new Promise((resolve, reject) => {
      this
        .makeSecurelyCall(path, method, params, token)
        .subscribe({
          next: (response) => {
            console.log("RPC Response:", response);
            if (response.error) {
              console.error("JSON-RPC Error:", response.error);
              let error;
              if (params[0] == "") {
                error = "Error while performing the pairing (step 1): " + response.error.message;
              } else {
                error = "Error while performing the pairing (step 2): " + response.error.message;
              }
              reject(new Error(error));
            }
            else {
              resolve(response.result.addressPairingMsg);
            }
          },
          error: (error) => {
            console.error("RPC Error:", error);
            reject(error);
          }
        });
    });
  }

  private checkOnboarding(token: string): Promise<any> {
    const path = 'api/v0/onboarding';
    const method = 'getStatus';
    const params = {
      methodId: this.getMethodId()
    };

    return new Promise((resolve, reject) => {
      if (params.methodId === null) {
        reject(new Error("MethodID is missing"));
        return;
      }
      this
        .makeSecurelyCall(path, method, params, token)
        .subscribe({
          next: (response) => {
            console.log("RPC Response:", response);
            if (response.error) {
              console.error("JSON-RPC Error:", response.error);
              reject(new Error("JSON-RPC Error"));
            }
            else {
              resolve(response.result);
            }
          },
          error: (error) => {
            console.error("RPC Error:", error);
            reject(error);
          }
        });
    });
  }

  private askForConsent(provider: string, token: string): Promise<any> {
    return this
      .signConsentEthereumMessage(provider)
      .then<ConsentSignature>((consentSignature: ConsentSignature) => {
        return new Promise((resolve, reject) => {
          const path = 'api/v0/onboarding';
          const method = 'consent';
          const params = {
            sign: consentSignature.sign,
            msg: consentSignature.msg,
            methodId: consentSignature.methodId
          };
          this.makeSecurelyCall(path, method, params, token).subscribe({
            next: (response) => {
              console.log('RPC Response:', response);
              if (response.error) {
                console.error('JSON-RPC Error:', response.error);
                reject(new Error('JSON-RPC Error'));
              } else {
                resolve(response.result);
              }
            },
            error: (error) => {
              console.error('RPC Error:', error);
              reject(error);
            }
          });
        });
    });
  }

  private async signConsentEthereumMessage(provider: string): Promise<ConsentSignature> {
    const date = new DatePipe('en-US').transform(new Date(), 'EEE, dd MMM YYYY HH:mm:ss ZZ');
    const msg_string = `[${date}] I accept that ${provider[0].toUpperCase() + provider.slice(1).toLowerCase()} may need to share personal information collected during the onboarding process, with ${this.getDappName()} and the entity to whom this website belongs.`
    return this.signEthereumMessage(msg_string).then<ConsentSignature>((signature: string) => {
      return {
        msg: msg_string,
        sign: signature,
        methodId: this.getMethodId()
      };
    });
  }

  private async signEthereumMessage(msg: string): Promise<string> {
      const { ethereum } = window as any;
      if (!ethereum) {
        throw new Error('Please install Metamask or any other compatible Ethereum wallet.');
      }
      try {
        const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        const account = accounts[0];
        const signature = await ethereum.request({
          method: 'personal_sign',
          params: [msg, account],
        });
        return signature;
      } catch (error) {
        console.error('Error while signing:', error);
        throw error;
      }
    }

  public getMethodId(): string {
        //console.log(this.urlParams.get('methodId'));
        return this.urlParams.get('methodId') ?? null;
    }

  private getDappName(): string {
    return (document.referrer != null && document.referrer != "") ?
      document.referrer.replace(/^(http:\/\/|https:\/\/)|(\/)$/g, '') :
      "the calling website";
  }

  private startOnboarding(type: string, provider: string, userInfo: UserInfo | null, token: string): Promise<string> {
    const path = 'api/v0/onboarding';
    const method = 'startOnboarding';
    const params = {
      type: type,
      provider: provider,
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      companyName: userInfo?.companyName,
      companyCountry: userInfo?.companyCountry
    };
    return new Promise((resolve, reject) => {
      this.makeSecurelyCall(path, method, params, token).subscribe({
        next: (response) => {
          console.log('RPC Response:', response);
          if (response.error) {
            console.error('JSON-RPC Error:', response.error);
            reject(response.error);
            return;
          }
          resolve(response.result);
        },
        error: (error) => {
          console.error('RPC Error:', error);
          reject(error);
        }
      });
    });
  }

  private setDelegations(addresses: string, token: string): Promise<string> {
    const path = 'api/v0/onboarding';
    const method = 'setDelegations';
    const params = {
      addresses: addresses,
    };
    return new Promise((resolve, reject) => {
      this.makeSecurelyCall(path, method, params, token).subscribe({
        next: (response) => {
          console.log('RPC Response:', response);
          if (response.error) {
            console.error('JSON-RPC Error:', response.error);
            reject(response.error);
            return;
          }
          resolve(response.result);
        },
        error: (error) => {
          console.error('RPC Error:', error);
          reject(error);
        }
      });
    });
  }

  public async handleOnboarding(token: string) {
    try {
      if (!token) {
        throw new Error('Token is missing');
      }
      try {
        await this.pairing(token);
      } catch (error) {
        console.log('Warning: pairing cannot be performed:', error);
      }
      const result = await this.checkOnboarding(token);
      /* Check if KYC and/or KYB are required */
      let hasKyc = false;
      let hasKyb = false;
      let needsKycFields = false;
      let needsKybFields = false;
      for (const provider of result.providers) {
        if (provider.status == false) {
          if (provider.type.toLowerCase() == "kyc") {
            hasKyc = true;
            switch (provider.name.toLowerCase()) {
              /* These providers needs to know the name of the user beforehand */
              case "sikoia":
                needsKycFields = true;
            }
          } else if (provider.type.toLowerCase() == "kyb") {
            hasKyb = true;
            switch (provider.name.toLowerCase()) {
              /* These providers needs to know information on the company beforehand */
              case "sumsub":
                needsKybFields = true;
            }
          }
        }
      }

      /* Ask the relevant user information if needed */
      let userInfo: UserInfo | null = null;
      if (hasKyc && hasKyb || needsKycFields || needsKybFields) {
        userInfo = await this.askUserInfo(hasKyc && hasKyb, needsKycFields, needsKybFields, (hasKyc && hasKyb) ? "" : (hasKyc ? "kyc" : "kyb"));
      }

      /* Ask to delegate KYB to specific addresses if relevant */
      let addresses: string | null = null;
      if (hasKyb && (!hasKyc || userInfo?.userType === "kyb")) {
        addresses = await this.askKybDelegation();
      }

      /* Check if onboarding steps are required */
      for (const provider of result.providers) {
        if ((provider.type.toLowerCase() == "kyc" || provider.type.toLowerCase() == "kyb") && hasKyc && hasKyb && provider.type.toLowerCase() !== userInfo?.userType) {
          continue;
        }
        if (provider.consent == false) {
          await this.askForConsent(provider.name.toLowerCase(), token);
        }
        if (provider.status == false) {
          /* Onboarding step required */
          await this.doProviderOnboarding(provider.type.toLowerCase(), provider.name.toLowerCase(), userInfo, token);
          if (provider.type.toLowerCase() == "kyb" && addresses != null) {
            /* Set the addresses allowed to make transaction on behalf of this KYB */
            await this.setDelegations(addresses, token);
          }
        }
      }
    } catch (error) {
      console.error('Error during the on-boarding process:', error);
      throw error;
    }
  }

  private doProviderOnboarding(type: string, provider: string, userInfo: UserInfo | null, token: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.startOnboarding(type, provider, userInfo, token).then(responseResult => {
        switch (provider) {
          case "synaps":
            this.doSynaps(type, responseResult, resolve, reject);
            break;

          case "sikoia":
            this.doSikoia(type, responseResult, resolve, reject);
            break;

          case "sumsub":
            this.doSumsub(type, userInfo, token, responseResult, resolve, reject);
            break;

          default:
            console.error('Unsupported KYC provider:', provider);
            reject('Unsupported KYC provider: ' + provider);
        }
      }).catch(error => {
        console.error('Onboarding Error:', error);
        reject(error);
      });
    });
  }

  private doSynaps(type: string, sessionId: string, resolve: (value: void | PromiseLike<void>) => void, reject: (reason?: any) => void): void {
    if (type === "kyb") {
      console.error('KYB not yet supported for Synaps');
      reject('KYB not yet supported for Synaps');
      return;
    }

    Synaps.init({
      sessionId: sessionId,
      onFinish: () => {
        resolve();
      }
    });
    Synaps.show();
  }

  private doSikoia(type: string, url: string, resolve: (value: void | PromiseLike<void>) => void, reject: (reason?: any) => void): void {
    if (type === "kyb") {
      console.error('KYB not yet supported for Sikoia');
      reject('KYB not yet supported for Sikoia');
      return;
    }

    const newWindow = window.open(url,"Sikoia KYC", "toolbar=no,scrollbars=no,location=no,statusbar=no,menubar=no,resizable=0,width=650,height=950");
  }

  private doSumsub(type: string, userInfo: UserInfo | null, securelyToken: string, accessToken: string, resolve: (value: void | PromiseLike<void>) => void, reject: (reason?: any) => void): void {
    this.modalRequired = true;
    const snsWebSdkInstance = SnsWebSdk
        .init(
            accessToken,
            // token update callback, must return Promise
            // Access token expired
            // get a new one and pass it to the callback to re-initiate the WebSDK
            () => this.getNewSumsubAccessToken(type, userInfo, securelyToken)
        )
        .withConf({
            lang: "en", //language of WebSDK texts and comments (ISO 639-1 format)
            email: "applicantEmail",
            phone: "applicantPhone",
            theme: "light",
        })
        .withOptions({ addViewportTag: false, adaptIframeHeight: true })
        // see below what kind of messages WebSDK generates
        .on("idCheck.onStepCompleted", (payload) => {
            console.log("onStepCompleted", payload);
            this.modalRequired = false;
            resolve();
        })
        .on("idCheck.onError", (error) => {
            console.log("onError", error);
            reject(error);
        })
        .build();

    // you are ready to go:
    // just launch the WebSDK by providing the container element for it
    snsWebSdkInstance.launch("#custom-modal");
  }

  private getNewSumsubAccessToken(type: string, userInfo: UserInfo | null, securelyToken: string) {
    return this.startOnboarding(type, "sumsub", userInfo, securelyToken); // get a new token from your backend
  }
}

interface ConsentSignature {
  msg: string;
  sign: string;
  methodId: string;
}

export interface UserInfo {
  firstName?: string;
  lastName?: string;
  companyName?: string;
  companyCountry?: string;
  userType?: string;
}
