<form [formGroup]="kybDelegationFormGroup" (ngSubmit)="onSubmit();">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <mat-icon class="material-symbols-rounded" inline="inline">account_balance_wallet</mat-icon>&nbsp;Which addresses are allowed to make transactions on behalf of your company (optional)?
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div formArrayName="addresses">
        <div *ngFor="let addressGroup of addresses.controls; let i = index" [formGroupName]="i">
          <mat-form-field appearance="outline">
            <mat-label>Address {{i + 1}}</mat-label>
            <input autocapitalize="words" enterkeyhint="done" formControlName="address" matInput type="text" />
            <button mat-icon-button matSuffix (click)="removeAddressField(i)">
              <mat-icon class="material-symbols-rounded">delete</mat-icon>
            </button>
            <mat-error *ngIf="addressGroup.get('address')?.errors?.['required']">
              This field is required.
            </mat-error>
            <mat-error *ngIf="(!addressGroup.get('address')?.errors?.['required'] && addressGroup.get('address')?.errors?.['invalidAddress'])">
              Invalid Ethereum address.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <button mat-stroked-button color="primary" type="button" (click)="addAddressField()">Add Address</button>
      <div class="button-container">
        <button color="primary" [disabled]="kybDelegationFormGroup.disabled || kybDelegationFormGroup.invalid" mat-mini-fab matSuffix type="submit">
          <mat-icon class="material-symbols-rounded">arrow_forward</mat-icon>
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</form>
