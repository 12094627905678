<div id="overlay" [ngStyle]="onboardingService.shouldDisplayCustomModal() ? {'display': 'block'} : {'display': 'none'}">
  <div id="custom-modal">
  </div>
</div>
<mat-card>
  <mat-card-header>
    <mat-card-title>
      Welcome{{ profileService.profileDocument$()?.name ? ', ' + profileService.profileDocument$()?.name + '.' : ellipsesService.ellipses$() }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-actions>
    <button
      (click)="authenticationService.signInAnonymously();"
      color="primary"
      mat-button="mat-button">
      <mat-icon class="material-symbols-rounded">
        logout
      </mat-icon>
      Sign out
    </button>
  </mat-card-actions>
</mat-card>
<button
  (click)="authenticationService.sendFirebaseTokenToOpener();"
  color="primary"
  mat-raised-button="">
  <mat-icon class="material-symbols-rounded">
    close
  </mat-icon>
  Close
</button>
