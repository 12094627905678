import { NgFor, NgIf }                                             from "@angular/common";
import { Component, Input, inject }                                from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatButtonModule }                                         from "@angular/material/button";
import { MatCardModule }                                           from "@angular/material/card";
import { MatOptionModule }                                         from '@angular/material/core';
import { MatFormFieldModule }                                      from "@angular/material/form-field";
import { MatIconModule }                                           from "@angular/material/icon";
import { MatInputModule }                                          from "@angular/material/input";
import { MatRadioModule }                                          from '@angular/material/radio';
import { MatSelectModule }                                         from '@angular/material/select';
import { OnboardingService, UserInfo }                             from "../../../../services";

type UserInfoFormGroupType = {
  firstName: FormControl<string>,
  lastName: FormControl<string>,
  companyName: FormControl<string>,
  companyCountry: FormControl<string>,
  userType: FormControl<string>
};

@Component({
  imports:     [
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatRadioModule,
    MatSelectModule,
    NgFor,
    NgIf,
    ReactiveFormsModule,
  ],
  selector: 'website-user-info',
  standalone:  true,
  templateUrl: './UserInfoComponent.html',
  styleUrls: ['./UserInfoComponent.sass']
})

export class UserInfoComponent {
  private _showUserType = false;
  @Input()
  set showUserType(value: boolean) {
    this._showUserType = value;
    if (value) {
      this.userInfoFormGroup.controls.userType.setValidators(Validators.required);
    } else {
      this.userInfoFormGroup.controls.userType.clearValidators();
    }
    this.userInfoFormGroup.controls.userType.updateValueAndValidity();
  }
  get showUserType(): boolean {
    return this._showUserType;
  }

  private _userType = "";
  @Input()
  set userType(value: string) {
    if (value === "kyc" && this.showKycFields) {
      this.userInfoFormGroup.controls.firstName.setValidators(Validators.required);
      this.userInfoFormGroup.controls.lastName.setValidators(Validators.required);
      this.userInfoFormGroup.controls.companyName.clearValidators();
      this.userInfoFormGroup.controls.companyCountry.clearValidators();
    } else if (value === "kyb" && this.showKybFields) {
      this.userInfoFormGroup.controls.firstName.clearValidators();
      this.userInfoFormGroup.controls.lastName.clearValidators();
      this.userInfoFormGroup.controls.companyName.setValidators(Validators.required);
      this.userInfoFormGroup.controls.companyCountry.setValidators(Validators.required);
    } else {
      this.userInfoFormGroup.controls.firstName.clearValidators();
      this.userInfoFormGroup.controls.lastName.clearValidators();
      this.userInfoFormGroup.controls.companyName.clearValidators();
      this.userInfoFormGroup.controls.companyCountry.clearValidators();
    }
    this._userType = value;
    this.userInfoFormGroup.controls.firstName.updateValueAndValidity();
    this.userInfoFormGroup.controls.lastName.updateValueAndValidity();
    this.userInfoFormGroup.controls.companyName.updateValueAndValidity();
    this.userInfoFormGroup.controls.companyCountry.updateValueAndValidity();
  }
  get userType(): string {
    return this._userType;
  }

  @Input() showKycFields = false;
  @Input() showKybFields = false;

  public readonly userInfoFormGroup: FormGroup<UserInfoFormGroupType> = new FormGroup<UserInfoFormGroupType>({
    firstName: new FormControl(
      "",
      {
        nonNullable: true,
        validators: Validators.required,
      }
    ),
    lastName: new FormControl(
      "",
      {
        nonNullable: true,
        validators: Validators.required,
      }
    ),
    companyName: new FormControl(
      "",
      {
        nonNullable: true,
        validators: Validators.required,
      }
    ),
    companyCountry: new FormControl(
      "",
      {
        nonNullable: true,
        validators: Validators.required,
      }
    ),
    userType: new FormControl(
      "",
      {
        nonNullable: true,
        validators: [],
      }
    ),
  });

  private readonly onboardingService: OnboardingService = inject<OnboardingService>(OnboardingService);

  constructor() {
    this.userInfoFormGroup.get('userType')!.valueChanges.subscribe(value => {
      this.userType = value;
    });
  }

  onSubmit() {
    const userInfo: UserInfo = {
      firstName: this.userInfoFormGroup.value.firstName,
      lastName: this.userInfoFormGroup.value.lastName,
      companyName: this.userInfoFormGroup.value.companyName,
      companyCountry: this.userInfoFormGroup.value.companyCountry,
      userType: this.userInfoFormGroup.value.userType,
    };

    console.log('User Info Submitted: ', userInfo);
    this.onboardingService.submitUserInfo(userInfo);
  }

  countries = [
    { name: 'Afghanistan', alpha3: 'AFG' },
    { name: 'Albania', alpha3: 'ALB' },
    { name: 'Algeria', alpha3: 'DZA' },
    { name: 'Andorra', alpha3: 'AND' },
    { name: 'Angola', alpha3: 'AGO' },
    { name: 'Antigua and Barbuda', alpha3: 'ATG' },
    { name: 'Argentina', alpha3: 'ARG' },
    { name: 'Armenia', alpha3: 'ARM' },
    { name: 'Australia', alpha3: 'AUS' },
    { name: 'Austria', alpha3: 'AUT' },
    { name: 'Azerbaijan', alpha3: 'AZE' },
    { name: 'Bahamas', alpha3: 'BHS' },
    { name: 'Bahrain', alpha3: 'BHR' },
    { name: 'Bangladesh', alpha3: 'BGD' },
    { name: 'Barbados', alpha3: 'BRB' },
    { name: 'Belarus', alpha3: 'BLR' },
    { name: 'Belgium', alpha3: 'BEL' },
    { name: 'Belize', alpha3: 'BLZ' },
    { name: 'Benin', alpha3: 'BEN' },
    { name: 'Bhutan', alpha3: 'BTN' },
    { name: 'Bolivia', alpha3: 'BOL' },
    { name: 'Bosnia and Herzegovina', alpha3: 'BIH' },
    { name: 'Botswana', alpha3: 'BWA' },
    { name: 'Brazil', alpha3: 'BRA' },
    { name: 'Brunei Darussalam', alpha3: 'BRN' },
    { name: 'Bulgaria', alpha3: 'BGR' },
    { name: 'Burkina Faso', alpha3: 'BFA' },
    { name: 'Burundi', alpha3: 'BDI' },
    { name: 'Cabo Verde', alpha3: 'CPV' },
    { name: 'Cambodia', alpha3: 'KHM' },
    { name: 'Cameroon', alpha3: 'CMR' },
    { name: 'Canada', alpha3: 'CAN' },
    { name: 'Central African Republic', alpha3: 'CAF' },
    { name: 'Chad', alpha3: 'TCD' },
    { name: 'Chile', alpha3: 'CHL' },
    { name: 'China', alpha3: 'CHN' },
    { name: 'Colombia', alpha3: 'COL' },
    { name: 'Comoros', alpha3: 'COM' },
    { name: 'Congo (Congo-Brazzaville)', alpha3: 'COG' },
    { name: 'Congo (Democratic Republic of the)', alpha3: 'COD' },
    { name: 'Costa Rica', alpha3: 'CRI' },
    { name: 'Croatia', alpha3: 'HRV' },
    { name: 'Cuba', alpha3: 'CUB' },
    { name: 'Cyprus', alpha3: 'CYP' },
    { name: 'Czech Republic', alpha3: 'CZE' },
    { name: 'Denmark', alpha3: 'DNK' },
    { name: 'Djibouti', alpha3: 'DJI' },
    { name: 'Dominica', alpha3: 'DMA' },
    { name: 'Dominican Republic', alpha3: 'DOM' },
    { name: 'Ecuador', alpha3: 'ECU' },
    { name: 'Egypt', alpha3: 'EGY' },
    { name: 'El Salvador', alpha3: 'SLV' },
    { name: 'Equatorial Guinea', alpha3: 'GNQ' },
    { name: 'Eritrea', alpha3: 'ERI' },
    { name: 'Estonia', alpha3: 'EST' },
    { name: 'Eswatini', alpha3: 'SWZ' },
    { name: 'Ethiopia', alpha3: 'ETH' },
    { name: 'Fiji', alpha3: 'FJI' },
    { name: 'Finland', alpha3: 'FIN' },
    { name: 'France', alpha3: 'FRA' },
    { name: 'Gabon', alpha3: 'GAB' },
    { name: 'Gambia', alpha3: 'GMB' },
    { name: 'Georgia', alpha3: 'GEO' },
    { name: 'Germany', alpha3: 'DEU' },
    { name: 'Ghana', alpha3: 'GHA' },
    { name: 'Greece', alpha3: 'GRC' },
    { name: 'Grenada', alpha3: 'GRD' },
    { name: 'Guatemala', alpha3: 'GTM' },
    { name: 'Guinea', alpha3: 'GIN' },
    { name: 'Guinea-Bissau', alpha3: 'GNB' },
    { name: 'Guyana', alpha3: 'GUY' },
    { name: 'Haiti', alpha3: 'HTI' },
    { name: 'Honduras', alpha3: 'HND' },
    { name: 'Hungary', alpha3: 'HUN' },
    { name: 'Iceland', alpha3: 'ISL' },
    { name: 'India', alpha3: 'IND' },
    { name: 'Indonesia', alpha3: 'IDN' },
    { name: 'Iran', alpha3: 'IRN' },
    { name: 'Iraq', alpha3: 'IRQ' },
    { name: 'Ireland', alpha3: 'IRL' },
    { name: 'Israel', alpha3: 'ISR' },
    { name: 'Italy', alpha3: 'ITA' },
    { name: 'Jamaica', alpha3: 'JAM' },
    { name: 'Japan', alpha3: 'JPN' },
    { name: 'Jordan', alpha3: 'JOR' },
    { name: 'Kazakhstan', alpha3: 'KAZ' },
    { name: 'Kenya', alpha3: 'KEN' },
    { name: 'Kiribati', alpha3: 'KIR' },
    { name: 'Korea (Democratic People\'s Republic of)', alpha3: 'PRK' },
    { name: 'Korea (Republic of)', alpha3: 'KOR' },
    { name: 'Kuwait', alpha3: 'KWT' },
    { name: 'Kyrgyzstan', alpha3: 'KGZ' },
    { name: 'Lao People\'s Democratic Republic', alpha3: 'LAO' },
    { name: 'Latvia', alpha3: 'LVA' },
    { name: 'Lebanon', alpha3: 'LBN' },
    { name: 'Lesotho', alpha3: 'LSO' },
    { name: 'Liberia', alpha3: 'LBR' },
    { name: 'Libya', alpha3: 'LBY' },
    { name: 'Liechtenstein', alpha3: 'LIE' },
    { name: 'Lithuania', alpha3: 'LTU' },
    { name: 'Luxembourg', alpha3: 'LUX' },
    { name: 'Madagascar', alpha3: 'MDG' },
    { name: 'Malawi', alpha3: 'MWI' },
    { name: 'Malaysia', alpha3: 'MYS' },
    { name: 'Maldives', alpha3: 'MDV' },
    { name: 'Mali', alpha3: 'MLI' },
    { name: 'Malta', alpha3: 'MLT' },
    { name: 'Marshall Islands', alpha3: 'MHL' },
    { name: 'Mauritania', alpha3: 'MRT' },
    { name: 'Mauritius', alpha3: 'MUS' },
    { name: 'Mexico', alpha3: 'MEX' },
    { name: 'Micronesia (Federated States of)', alpha3: 'FSM' },
    { name: 'Moldova (Republic of)', alpha3: 'MDA' },
    { name: 'Monaco', alpha3: 'MCO' },
    { name: 'Mongolia', alpha3: 'MNG' },
    { name: 'Montenegro', alpha3: 'MNE' },
    { name: 'Morocco', alpha3: 'MAR' },
    { name: 'Mozambique', alpha3: 'MOZ' },
    { name: 'Myanmar', alpha3: 'MMR' },
    { name: 'Namibia', alpha3: 'NAM' },
    { name: 'Nauru', alpha3: 'NRU' },
    { name: 'Nepal', alpha3: 'NPL' },
    { name: 'Netherlands', alpha3: 'NLD' },
    { name: 'New Zealand', alpha3: 'NZL' },
    { name: 'Nicaragua', alpha3: 'NIC' },
    { name: 'Niger', alpha3: 'NER' },
    { name: 'Nigeria', alpha3: 'NGA' },
    { name: 'North Macedonia', alpha3: 'MKD' },
    { name: 'Norway', alpha3: 'NOR' },
    { name: 'Oman', alpha3: 'OMN' },
    { name: 'Pakistan', alpha3: 'PAK' },
    { name: 'Palau', alpha3: 'PLW' },
    { name: 'Palestine, State of', alpha3: 'PSE' },
    { name: 'Panama', alpha3: 'PAN' },
    { name: 'Papua New Guinea', alpha3: 'PNG' },
    { name: 'Paraguay', alpha3: 'PRY' },
    { name: 'Peru', alpha3: 'PER' },
    { name: 'Philippines', alpha3: 'PHL' },
    { name: 'Poland', alpha3: 'POL' },
    { name: 'Portugal', alpha3: 'PRT' },
    { name: 'Qatar', alpha3: 'QAT' },
    { name: 'Romania', alpha3: 'ROU' },
    { name: 'Russian Federation', alpha3: 'RUS' },
    { name: 'Rwanda', alpha3: 'RWA' },
    { name: 'Saint Kitts and Nevis', alpha3: 'KNA' },
    { name: 'Saint Lucia', alpha3: 'LCA' },
    { name: 'Saint Vincent and the Grenadines', alpha3: 'VCT' },
    { name: 'Samoa', alpha3: 'WSM' },
    { name: 'San Marino', alpha3: 'SMR' },
    { name: 'Sao Tome and Principe', alpha3: 'STP' },
    { name: 'Saudi Arabia', alpha3: 'SAU' },
    { name: 'Senegal', alpha3: 'SEN' },
    { name: 'Serbia', alpha3: 'SRB' },
    { name: 'Seychelles', alpha3: 'SYC' },
    { name: 'Sierra Leone', alpha3: 'SLE' },
    { name: 'Singapore', alpha3: 'SGP' },
    { name: 'Slovakia', alpha3: 'SVK' },
    { name: 'Slovenia', alpha3: 'SVN' },
    { name: 'Solomon Islands', alpha3: 'SLB' },
    { name: 'Somalia', alpha3: 'SOM' },
    { name: 'South Africa', alpha3: 'ZAF' },
    { name: 'South Sudan', alpha3: 'SSD' },
    { name: 'Spain', alpha3: 'ESP' },
    { name: 'Sri Lanka', alpha3: 'LKA' },
    { name: 'Sudan', alpha3: 'SDN' },
    { name: 'Suriname', alpha3: 'SUR' },
    { name: 'Sweden', alpha3: 'SWE' },
    { name: 'Switzerland', alpha3: 'CHE' },
    { name: 'Syrian Arab Republic', alpha3: 'SYR' },
    { name: 'Tajikistan', alpha3: 'TJK' },
    { name: 'Tanzania, United Republic of', alpha3: 'TZA' },
    { name: 'Thailand', alpha3: 'THA' },
    { name: 'Timor-Leste', alpha3: 'TLS' },
    { name: 'Togo', alpha3: 'TGO' },
    { name: 'Tonga', alpha3: 'TON' },
    { name: 'Trinidad and Tobago', alpha3: 'TTO' },
    { name: 'Tunisia', alpha3: 'TUN' },
    { name: 'Turkey', alpha3: 'TUR' },
    { name: 'Turkmenistan', alpha3: 'TKM' },
    { name: 'Tuvalu', alpha3: 'TUV' },
    { name: 'Uganda', alpha3: 'UGA' },
    { name: 'Ukraine', alpha3: 'UKR' },
    { name: 'United Arab Emirates', alpha3: 'ARE' },
    { name: 'United Kingdom of Great Britain and Northern Ireland', alpha3: 'GBR' },
    { name: 'United States of America', alpha3: 'USA' },
    { name: 'Uruguay', alpha3: 'URY' },
    { name: 'Uzbekistan', alpha3: 'UZB' },
    { name: 'Vanuatu', alpha3: 'VUT' },
    { name: 'Venezuela (Bolivarian Republic of)', alpha3: 'VEN' },
    { name: 'Viet Nam', alpha3: 'VNM' },
    { name: 'Yemen', alpha3: 'YEM' },
    { name: 'Zambia', alpha3: 'ZMB' },
    { name: 'Zimbabwe', alpha3: 'ZWE' }
  ];
}
