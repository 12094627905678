<main>
  <router-outlet />
  <aside>
    <website-signed-in *ngIf="(authenticationService.user$()?.isAnonymous === false && onboardingService.getProviders() && onboardingService.isAuthRequired())" />
    <website-signed-out *ngIf="(authenticationService.user$()?.isAnonymous === true && onboardingService.getProviders() && onboardingService.isAuthRequired())" />
  </aside>
</main>
<footer>
  <span>
    v{{ packageVersion + (gitInfo.dirty ? "-next" : "") }}+{{ gitInfo.hash }}
  </span>
</footer>
