import { Component } from '@angular/core';
import { Synaps } from "@synaps-io/verify-sdk";

@Component({
  selector: 'website-kyc-synaps',
  standalone:  true,
  templateUrl: './KycSynapsComponent.html',
  styleUrls: ['./KycSynapsComponent.sass']
})
export class KycSynapsComponent {
  handleOpen = () => {
    Synaps.show()
  }

  ngOnInit() {
    Synaps.init({
      sessionId: "d4885aa0-d8b1-45c5-beed-5526d9c3823c",
      onFinish: () => {
        alert("finish")
      }
    })
  }
}