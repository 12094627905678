<form
  [formGroup]="signUpFormGroup"
  (ngSubmit)="signUpSubmit();">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <mat-icon
          class="material-symbols-rounded"
          inline="inline">passkey</mat-icon>&nbsp;{{ signUpWorking$() ? "Working" + ellipsesService.ellipses$() : "Sign up with passkey" }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field appearance="outline">
        <mat-label>
          Username
        </mat-label>
        <input
          autocapitalize="words"
          enterkeyhint="done"
          formControlName="name"
          matInput="matInput"
          type="text" />
        <mat-error *ngIf="signUpFormGroup.controls.name.errors?.['required']">
          This is required.
        </mat-error>
        <button
          color="primary"
          [disabled]="signUpFormGroup.disabled || signUpFormGroup.invalid"
          mat-mini-fab="mat-mini-fab"
          matSuffix
          type="submit">
          <mat-icon class="material-symbols-rounded">
            arrow_forward
          </mat-icon>
        </button>
      </mat-form-field>
    </mat-card-content>
  </mat-card>
</form>
<p>
  or
</p>
<button
  (click)="signInWithPasskey();"
  color="primary"
  [disabled]="signInWorking$() || signUpWorking$()"
  mat-raised-button="mat-raised-button">
  <mat-icon class="material-symbols-rounded">
    passkey
  </mat-icon>
  {{ signInWorking$() ? "Working" + ellipsesService.ellipses$() : "Sign in with passkey" }}
</button>
