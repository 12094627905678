<form
  [formGroup]="userInfoFormGroup"
  (ngSubmit)="onSubmit();">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <mat-icon
          class="material-symbols-rounded"
          inline="inline">person</mat-icon>&nbsp;Who are you ?
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-radio-group *ngIf="showUserType === true" formControlName="userType" aria-label="Select an option">
        <mat-radio-button value="kyc">I'm a natural person</mat-radio-button>
        <mat-radio-button value="kyb">I represent a legal entity</mat-radio-button>
      </mat-radio-group>
      <mat-form-field *ngIf="userType === 'kyc' && showKycFields" appearance="outline">
        <mat-label>
          First name
        </mat-label>
        <input
          autocapitalize="words"
          enterkeyhint="done"
          formControlName="firstName"
          matInput="matInput"
          type="text" />
        <mat-error *ngIf="userInfoFormGroup.controls.firstName.errors?.['required']">
          This is required.
        </mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="userType === 'kyc' && showKycFields" appearance="outline">
        <mat-label>
          Last name
        </mat-label>
        <input
          autocapitalize="words"
          enterkeyhint="done"
          formControlName="lastName"
          matInput="matInput"
          type="text" />
        <mat-error *ngIf="userInfoFormGroup.controls.lastName.errors?.['required']">
          This is required.
        </mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="userType === 'kyb' && showKybFields" appearance="outline">
        <mat-label>
          Company name
        </mat-label>
        <input
          autocapitalize="words"
          enterkeyhint="done"
          formControlName="companyName"
          matInput="matInput"
          type="text" />
        <mat-error *ngIf="userInfoFormGroup.controls.companyName.errors?.['required']">
          This is required.
        </mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="userType === 'kyb' && showKybFields" appearance="outline">
        <mat-label>
          Company Country
        </mat-label>
        <mat-select formControlName="companyCountry">
          <mat-option *ngFor="let country of countries" [value]="country.alpha3">
            {{country.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="userInfoFormGroup.controls.companyCountry.errors?.['required']">
          This is required.
        </mat-error>
      </mat-form-field>
      <div class="button-container">
        <button
          color="primary"
          [disabled]="userInfoFormGroup.disabled || userInfoFormGroup.invalid"
          mat-mini-fab="mat-mini-fab"
          matSuffix
          type="submit">
          <mat-icon class="material-symbols-rounded">
            arrow_forward
          </mat-icon>
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</form>
