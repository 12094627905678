import { Environment } from "./app/interfaces";


export const environment: Environment = {
  projectName: "website",
  firebase:    {
    apiKey: "AIzaSyBn8Zm7-gMftJy9vHz_rzl-UZnQ-M37ETw",
    authDomain: "webauthn-front-test.firebaseapp.com",
    projectId: "webauthn-front-test",
    storageBucket: "webauthn-front-test.appspot.com",
    messagingSenderId: "418176494450",
    appId: "1:418176494450:web:db170346ab6b41d4418beb"
  },
  production:  false,
  recaptchaSiteKey: "6LeqACEpAAAAABZrXo-vyKitbo_xEJqtUxx03yAm",
  backendAddress: "https://dev.securely.id"
};
