import { Component, Inject } from '@angular/core';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { SafeUrlPipe } from '../../../pipes'; // Assuming this is your custom pipe

@Component({
  selector: 'website-modal',
  standalone:  true,
  imports: [CommonModule, MatDialogModule, SafeUrlPipe],
  templateUrl: './ModalComponent.html',
  styleUrls: ['./ModalComponent.css']
})
export class ModalComponent {
  externalUrl: string;

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.externalUrl = data.url;
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
