import { NgFor, NgIf } from "@angular/common";
import { Component, inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { OnboardingService } from "../../../../services";
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Component({
  imports: [
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    NgFor,
    NgIf,
    ReactiveFormsModule,
  ],
  selector: 'website-user-info',
  standalone: true,
  templateUrl: './KybDelegationComponent.html',
  styleUrls: ['./KybDelegationComponent.sass']
})
export class KybDelegationComponent {
  public kybDelegationFormGroup: FormGroup;

  private readonly onboardingService: OnboardingService = inject<OnboardingService>(OnboardingService);

  constructor(private fb: FormBuilder) {
    this.kybDelegationFormGroup = this.fb.group({
      addresses: this.fb.array([])
    });
  }

  get addresses() {
    return this.kybDelegationFormGroup.get('addresses') as FormArray;
  }

  createAddressFormGroup(): FormGroup {
    return this.fb.group({
      address: ['', [Validators.required, this.ethereumAddressValidator()]]
    });
  }

  addAddressField() {
    this.addresses.push(this.createAddressFormGroup());
  }

  removeAddressField(index: number) {
    this.addresses.removeAt(index);
  }

  ethereumAddressValidator(): ValidatorFn {
    const ethAddressPattern = /^0x[a-fA-F0-9]{40}$/;
    return (ctrl: AbstractControl): ValidationErrors | null => {
        return ethAddressPattern.test(ctrl.value) ? null : { invalidAddress: true };
    };
  }

  onSubmit() {
    if (this.kybDelegationFormGroup.valid) {
      const addresses = this.kybDelegationFormGroup.value.addresses.map((item: { address: string; }) => item.address);
      console.log('KYB delegated to: ', addresses);
      this.onboardingService.submitKybDelegation(addresses);
    }
  }
}
